<template>
  <div class="home">
    <van-nav-bar title="客户" left-arrow id="reset"  left-text="返回" @click-left="onClickLeft"/>
    <div class="header">
      <div class="top1">
        <img src="../static/客户/touxiang2.png" alt="">
        <p class="name">{{list.name}}</p>
      </div>
      <p class="t1">负责人 {{list.owner.full_name}}</p>
      <!-- <p class="t1">最后跟进时间 2021-4-28 12:00:00 </p> -->
      <p class="t1"  @click="tellTo(list.contacts_telephone)">
        联系电话
        <span style="color:#4099d9;margin:0 10px">{{list.contacts_telephone}}</span>
        <img src="../static/动态详情1/dianhua.png" alt="" height="17" width="14">
      </p>
    </div>
    <div class="con">
      <van-tabs v-model="active" title-inactive-color="#8D8D8D" title-active-color="#1583D2" color="#1583D2">
        <van-tab title="沟通日志">
          <div class="tabItem"  v-for="(item,index) in busiesslist" :key="index">
            <img src="../static/客户/touxiang2.png" alt="" width="44" height="44" style="margin-top:15px">
            <div class="tapTxt">
              <p class="tabName">{{item.owner.user_name}}</p>
              <p class="tabT">
                {{item.content}}
              </p>
            </div>

          </div>
        </van-tab>
     <!--   <van-tab title="商机">内容 2</van-tab>
        <van-tab title="合同">内容 3</van-tab>
        <van-tab title="财务">内容 4</van-tab>
        <van-tab title="附件">内容 4</van-tab> -->
      </van-tabs>
    </div>
    <div class="footer">
      <!-- <img src="../static/动态详情1/tianjia.png" alt=""> -->
    </div>
  </div>
</template>

<script>
	import moment from 'moment';

export default {
  data() {
    return {
      active: 0,
	  list:{},
	  busiesslist:{},
	  
    };
  },created () {
 			// console.log(this.$route.query.id);
    this.getbusiness()
    this.getHistoryData()
  },
methods:{
	
		tellTo(contacts_telephone)
		{
	 		console.log(contacts_telephone);
			this.$router.push({
			  path: `/bohao?id=`+contacts_telephone,
			})
			
		},
	routeTo(customer_id)
	{
 		console.log(customer_id);
		this.$router.push({
		  path: `/kehuxiangqing?id=`+customer_id,
		})
		
	},
	dateFormat(row, column) {
      var date = row[column.property];
      if (date == undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD");
    },
 
    onClickLeft() {
    this.$router.go(-1);//返回上一层
    },
 getbusiness()
 {
	 
	 	let param1 = new URLSearchParams();
	 	
	 	param1.append("module", 'customer');
	 	param1.append("id", this.$route.query.id);
	 	 param1.append("token", this.$store.getters. getDemoValue);
	 	
	 	this.axios.post('/vue.php?m=index&a=loglist',param1).then(res1 => {
	 		
	 		if (res1.data.status === -1) {
	 			setTimeout(()=>{
	 				this.$router.push({
	 				  path:"/"
	 				})
	 			},1000)
	 		}
	 		if (res1.data.status === 1) {
	 			
	 			console.log(11111);
				this.tableData = res1.data.list;//后台获取的数据

this.busiesslist = JSON.parse(JSON.stringify(this.tableData));//把数据转化后赋予this.datalist
	 			// this.busiesslist = res1.data.list;
	 			console.log(this.busiesslist);
	 		}
	 		// console.log(res1);
	 	}).catch(function (error){
	 		// Message.info('hello');
	 		console.log(error);
	 	});
	 	
 },
 
 getHistoryData(){
 	let param = new URLSearchParams();
 	
 	param.append("id", this.$route.query.id);
 	 param.append("token", this.$store.getters. getDemoValue);
 	
 	this.axios.post('/vue.php?m=customer&a=dynamic',param).then(res => {
 		if (res.data.status === -1) {
 			// uni.setStorageSync('name',res.data.name);
 			// uni.setStorageSync('session_id',res.data.session_id);
 			// uni.setStorageSync('token',res.data.token);
 			// global.name = res.data.name;
 			// global.session_id = res.data.session_id;
 			// global.token = res.data.token;
 			// Message.info(res.data);
 			setTimeout(()=>{
 				this.$router.push({
 				  path:"/"
 				})
 			},1000)
 		}
 		if (res.data.status === 1) {
 			
			this.list = res.data.data;
 			console.log(this.list);
 			// this.customer_week = res.data.data.customer_count.week;
 			// this.customer_month = res.data.data.customer_count.month; 
 			// this.log_count_week = res.data.data.log_count.week; 
 			// this.log_count_month = res.data.data.log_count.month; 
 			
 			// uni.setStorageSync('name',res.data.name);
 			// uni.setStorageSync('session_id',res.data.session_id);
 			// uni.setStorageSync('token',res.data.token);
 			global.name = this.name;
 			global.session_id = this.session_id;
 			global.token = this.token;
 			// Message.info(res.data);
 			// setTimeout(()=>{
 			// 	this.$router.push({
 			// 	  path:"/"
 			// 	})
 			// },1000)
 		}
 		console.log(res);
 	}).catch(function (error){
 		// Message.info('hello');
 		console.log(error);
 	});
 	
   // this.$router.push({
   //   path:"/crm"
   // })
 }
 
},

};
</script>

<style lang="scss" scoped>
.home {
  background: #f2f2f2;
  padding-bottom: 20px;
}
.header {
  padding: 5px 26px;
  background: #ffffff;
  margin-bottom: 20px;
}
.footer {
  position: fixed;
  bottom: 80px;
  right: 0;
  img {
    width: 63px;
    height: 63px;
  }
}
.t1 {
  font-size: 14px;
  font-weight: normal;
  color: #8d8d8d;
}
.con {
  background: #ffffff;
  padding: 0 20px;
}
.tapTxt {
  width: 80%;
  word-wrap: break-word;
  word-break: break-all;
  margin-left: 10px;
}
.tabName {
  font-size: 15px;
  font-weight: normal;
  color: #484848;
}
.tabT {
  font-size: 15px;
  font-weight: normal;
  color: #a1a1a1;
}
.tabItem {
  background: #ffffff;
  margin-top: 5px;
  padding: 5px 0;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
}
.top1 {
  display: flex;
  align-items: center;
  img {
    width: 31px;
    height: 31px;
    margin-right: 12px;
  }
  p {
    height: 20px;
    font-size: 21px;
    font-weight: normal;
    color: #373c40;
  }
}
#reset ::v-deep .van-nav-bar__title {
  color: #ffffff !important;
}
.van-nav-bar {
  background: #1288f4;
}
#reset ::v-deep .van-icon.van-icon-arrow-left.van-nav-bar__arrow {
  color: white !important;
}
</style>